var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.data.block, { "mb-2": _vm.layout !== "template7" }],
      attrs: { id: "banner-type5" }
    },
    [
      _c(
        "swiper",
        {
          ref: "slider",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOption }
        },
        [
          _vm._l(_vm.banners, function(banner, index) {
            return _c("swiper-slide", { key: banner._id + index }, [
              _c("div", [
                _c("img", {
                  staticClass: "slider-img rounded",
                  attrs: {
                    contain: "",
                    width: "100%",
                    src: _vm.baseImgUrl + banner.mobile_image || _vm.image
                  },
                  on: {
                    click: function($event) {
                      return _vm.clickBanner(
                        banner.mobile_hyperlink,
                        _vm.$router
                      )
                    }
                  }
                })
              ])
            ])
          }),
          _c(
            "div",
            {
              staticClass: "swiper-button-prev",
              attrs: { slot: "button-prev" },
              on: { click: _vm.slidePrev },
              slot: "button-prev"
            },
            [
              _c(
                "v-icon",
                { staticClass: "white--text", attrs: { medium: "" } },
                [_vm._v(" mdi-chevron-left ")]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "swiper-button-next",
              attrs: { slot: "button-next" },
              on: { click: _vm.sliderNext },
              slot: "button-next"
            },
            [
              _c(
                "v-icon",
                { staticClass: "white--text", attrs: { medium: "" } },
                [_vm._v(" mdi-chevron-right ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }